import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'create-breakpoints';
import { Button, Icon } from '@mindmatch/ui';

const COOKIE_NAME = 'cookie_banner';

const CookieBanner = styled.div`
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(20px);
  border-radius: 3px;
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25), 0 3px 7px -3px rgba(0, 0, 0, 0.3);
  color: var(--text-black);
  font-size: var(--font-size-medium);

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;

  ${breakpoint.small`
    top: initial;
    bottom: 10px;
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  `}
`;

CookieBanner.Body = styled.div`
  text-align: center;
  padding: 8px 5px 8px 15px;
`;

CookieBanner.Dismiss = styled(props => {
  return React.createElement(
    Button,
    { variant: 'link', ...props },
    <Icon name="close" size={18} />
  );
})`
  padding: 8px;
`;

export default class extends Component {
  state = {
    hasAcceptedCookies: this.hasAcceptedCookies()
  };

  setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000 * days);
    window.document.cookie = `${name}=${value};path=/;expires=${expires.toGMTString()}`;
  }

  hasAcceptedCookies() {
    //  Support SSR
    try {
      const cookies = window.document.cookie.split(';');
      return cookies.filter(item => item.includes(`${COOKIE_NAME}=1`)).length > 0;
    } catch (error) {
      // Prevent banner flickering for initial render
      return true;
    }
  }

  handleAccept = e => {
    this.setCookie(COOKIE_NAME, 1, 3650);
    this.setState({
      hasAcceptedCookies: 1
    });
  };

  render() {
    const { hasAcceptedCookies } = this.state;

    if (hasAcceptedCookies) {
      return null;
    }

    return (
      <CookieBanner>
        <CookieBanner.Body>
          By using this website, you agree to our{' '}
          <Link to="/legal/privacy-policy">cookie policy</Link>
        </CookieBanner.Body>
        <CookieBanner.Dismiss aria-label="Close cookie banner" onClick={this.handleAccept} />
      </CookieBanner>
    );
  }
}
