let typeformLeadCaptureInstance = null;

export const requestDemo = () => {
  window.Calendly.showPopupWidget('https://calendly.com/amarahlers/30min');
};

export const leadCapture = () => {
  if (!typeformLeadCaptureInstance) {
    typeformLeadCaptureInstance = window.typeformEmbed.makePopup(
      'https://form.typeform.com/to/dsboiw',
      {
        hideHeaders: true,
        hideFooter: false
      }
    );
  }

  typeformLeadCaptureInstance.open();
};
