import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { leadCapture } from 'utils';
import breakpoint, { breakpoints } from 'create-breakpoints';
import LayoutContainer from 'components/LayoutContainer';
import Logo from 'components/Logo';
import Button from 'components/Button';

const appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(.98) translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const Header = styled.header`
  background-color: ${props => (props.theme === 'light' ? 'var(--bg-blue)' : '#edf4fe')};
  padding: 16px 0;

  ${breakpoint.medium`
    background-color: transparent;
    color: ${props => (props.theme === 'light' ? 'var(--text-white)' : 'var(--text-gray)')};
    padding: 0;
    position: absolute;
    left: 0;
    top: 30px;
    right: 0;
    z-index: 1;
  `};

  ${LayoutContainer} {
    display: flex;
    align-items: center;
  }
`;

Header.Logo = styled(Link)`
  ${breakpoint.medium`
    margin-right: 20px;
    display: flex;
    align-items: center;
  `};
`;

Header.MenuToggle = styled.button.attrs({
  'aria-label': 'Toggle navigation'
})`
  background: none;
  border: 0;
  color: ${props => (props.theme === 'light' ? 'var(--text-white)' : 'var(--text-black)')};
  cursor: pointer;
  padding: 0;
  margin-left: auto;

  ${breakpoint.medium`
    display: none;
  `};
`;

Header.Menu = styled.div`
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 3px;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding: 8px 7px;
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  z-index: 1;

  visibility: ${props => (props.menuActive ? 'visible' : 'hidden')};
  transform: ${props => (props.menuActive ? 'scale(1)' : 'scale(.95)')};
  opacity: ${props => (props.menuActive ? 1 : 0)};
  transition: transform, opacity 0.25s ease;
  will-change: transform, opacity;
  pointer-events: ${props => (props.menuActive ? 'auto' : 'none')};

  ${breakpoint.medium`
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    position: static;
    flex-direction: row;
    flex: 1;

    visibility: visible;
    transition: none;
    will-change: auto;
    pointer-events: auto;
    transform: scale(1);
    opacity: 1;
  `};
`;

const GlobalNav = styled.nav`
  display: flex;
  flex-direction: column;

  ${breakpoint.medium`
    background-color: transparent;
    align-items: center;
    flex-direction: row;
  `}
`;

GlobalNav.Item = styled(Link)`
  color: currentColor;
  cursor: pointer;
  display: block;
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  padding: 16px 20px;
  transition: color 0.1s ease;

  ${breakpoint.medium`
    display: inline-block;
    font-weight: var(--font-weight-default);
  `}

  &:hover,
  [open] & {
    opacity: 0.8;
    text-decoration: none;
  }
`;

GlobalNav.Dropdown = styled.details`
  ${breakpoint.medium`
    position: relative;
  `}

  > *:not(summary) {
    ${breakpoint.medium`
      background-color: rgba(255, 255, 255, 0.98);
      /* backdrop-filter: blur(10px); */
      border-radius: 3px;
      box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.8), 0 30px 60px -30px rgba(0, 0, 0, 0.5);
      min-width: 200px;
      padding: 20px;
      position: absolute;
      left: 0;
      will-change: transform, opacity;
      animation: ${appear} 0.4s cubic-bezier(0.73, 0.005, 0.22, 1);
    `}
  }

  > summary:focus {
    outline: 0;
  }

  > summary::-webkit-details-marker {
    display: none;
  }
`;

GlobalNav.Dropdown.Item = styled(Link)`
  color: currentColor;
  display: block;
  padding: 10px 20px;

  ${breakpoint.medium`
    color: var(--text-gray);
    font-size: var(--font-size-medium);
    padding: 8px 0;


    &:hover,
    &[aria-current='page'] {
      color: var(--text-blue);
      text-decoration: none;
    }
  `}
`;

const SecondaryNav = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint.medium`
    margin-left: auto;
  `}

  ${Button} {
    display: none;

    ${breakpoint.large`
      display: inherit;
    `}
  }
`;

SecondaryNav.Item = styled(GlobalNav.Item)`
  margin-right: -16px;

  ${breakpoint.large`
    margin-right: 20px;
  `}
`;

export default class extends Component {
  dropdownReference = null;

  state = {
    menuActive: false
  };

  toggleMenu = () => {
    this.setState(prevState => ({ menuActive: !prevState.menuActive }));
  };

  componentDidMount() {
    document.addEventListener('mouseover', this.dropdownHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseover', this.dropdownHandler, false);
  }

  dropdownHandler = e => {
    if (!window.matchMedia(`(min-width: ${breakpoints.medium}px)`).matches) return;

    const detailsElement = e.target.closest('details');
    const newRef = this.dropdownReference && !this.dropdownReference.isSameNode(detailsElement);

    if (!detailsElement || newRef) {
      this.dropdownReference && this.dropdownReference.removeAttribute('open');
      this.dropdownReference = null;
      return;
    }

    detailsElement.setAttribute('open', '');
    this.dropdownReference = detailsElement;
  };

  render = () => (
    <Header theme={this.props.theme}>
      <LayoutContainer>
        <Header.Logo to="/" aria-label="MindMatch homepage">
          <Logo width="134" height="30" theme={this.props.theme} />
        </Header.Logo>
        <Header.MenuToggle theme={this.props.theme} onClick={this.toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
          </svg>
        </Header.MenuToggle>
        <Header.Menu menuActive={this.state.menuActive}>
          <Header.MenuToggle onClick={this.toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </Header.MenuToggle>
          <GlobalNav>
            <GlobalNav.Item as="a" href="/#product">
              Product
            </GlobalNav.Item>
            <GlobalNav.Item as="a" href="/#features">
              Features
            </GlobalNav.Item>
            <GlobalNav.Item as="a" href="/#contact">
              Contact
            </GlobalNav.Item>
          </GlobalNav>

          <SecondaryNav>
            <Button onClick={leadCapture}>Receive candidates</Button>
          </SecondaryNav>
        </Header.Menu>
      </LayoutContainer>
    </Header>
  );
}
