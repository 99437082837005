import { createGlobalStyle } from 'styled-components';

const GlobalAppStyle = createGlobalStyle`
  :root {
    --blue: #1f6de0;
    --green: #22d3a6;
    --black: #171d33;
    --gray: #939ba4;

    --white: #ffffff;

    --text-white: var(--white);
    --text-black: var(--black);
    --text-gray: var(--gray);
    --text-blue: var(--blue);
    --text-blue-light: #7ca8e8;
    --text-green: var(--green);

    --bg-white: var(--white);
    --bg-green: var(--green);
    --bg-blue: #206ad6;
    --bg-blue-light: #9ac0f5;
    --bg-gray-light: #fafafa;

    --border-gray: #c4cdd5;

    --font-size-default: 16px;
    --font-size-medium: 14px;
    --font-size-small: 12px;

    --font-weight-bold: 600;
    --font-weight-semibold: 500;
    --font-weight-normal: 400;
    --font-weight-light: 300;

    box-sizing: border-box;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: var(--font-size-default);

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-size: inherit;
    margin: 0;
  }

  a {
    color: var(--text-blue-light);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: var(--font-weight-normal);
    margin: 0;
  }
`;

export default GlobalAppStyle;
