import styled from 'styled-components';

const Button = styled.button`
  background-color: var(--green);
  border: 0;
  border-radius: 30px;
  color: var(--text-white);
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-semibold);
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  padding: 18px 36px;
  transition: all 0.15s ease;
  outline: 0;

  &:hover {
    text-decoration: none;
    filter: brightness(95%);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  &:focus {
    box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em rgba(34, 211, 166, 0.33);
  }
`;

export default Button;
