import styled, { css } from 'styled-components';
import { space, textAlign } from 'styled-system';

const Heading = styled.h1`
  ${space} ${textAlign}

  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  position: relative;

  ::after {
    content: '';
    display: block;
    height: 1px;
    background-color: var(--border-gray);
    margin-top: 14px;
    width: 50%;
    max-width: 70px;

    ${props =>
      props.textAlign === 'center' &&
      css`
        margin-left: auto;
        margin-right: auto;
      `}
  }
`;

Heading.propTypes = {
  ...textAlign.propTypes
};

Heading.defaultProps = {
  textAlign: 'center'
};

export default Heading;
