import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { leadCapture } from 'utils';
import SEO from 'components/SEO';
import GlobalAppStyle from 'components/GlobalAppStyle';
import CookieBanner from 'components/CookieBanner';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';

const GlobalCTA = styled.div`
  text-align: center;
  margin-top: 60px;
  margin-bottom: 110px;
`;

const Layout = ({ title, description, theme, children }) => (
  <Fragment>
    <Helmet>
      <html lang="en" />
      <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
      <link rel="dns-prefetch" href="https://assets.calendly.com" />
      <link rel="dns-prefetch" href="https://embed.typeform.com" />
      <link href="https://fonts.googleapis.com/css?family=Lato:400,700" rel="stylesheet" />
      <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
      <script src="https://assets.calendly.com/assets/external/widget.js" async defer />
      <script src="https://embed.typeform.com/embed.js" async defer />
    </Helmet>

    <SEO title={title} description={description} />
    <GlobalAppStyle />

    <div id="root">
      <Header theme={theme} />
      {children}
      <GlobalCTA>
        <Button onClick={leadCapture}>Receive candidates →</Button>
      </GlobalCTA>
      <Footer />

      <CookieBanner />
    </div>
  </Fragment>
);

Layout.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.oneOf(['light']),
  children: PropTypes.node.isRequired
};

export default Layout;
