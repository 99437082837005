import styled from 'styled-components';
import { styles, compose } from 'styled-system';

const COMMON = compose(
  styles.color,
  styles.space
);

const TYPOGRAPHY = compose(
  styles.fontFamily,
  styles.fontSize,
  styles.fontWeight,
  styles.lineHeight,
  styles.textAlign
);

const Text = styled.div`
  ${TYPOGRAPHY} ${COMMON}

  display: block;
`;

Text.propTypes = {
  ...COMMON.propTypes,
  ...TYPOGRAPHY.propTypes
};

export default Text;
