import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO(pageData) {
  return (
    <StaticQuery
      query={graphql`
        query SEO {
          site {
            siteMetadata {
              defaultTitle: title
              titleTemplate
              defaultDescription: description
              siteUrl
              siteName: name
              defaultImage: image
              twitterUsername
            }
          }
        }
      `}
      render={({ site: { siteMetadata: seo } }) => {
        const title = pageData.title || seo.defaultTitle;
        const description = pageData.description || seo.defaultDescription;
        const image = `${seo.siteUrl}/${pageData.image || seo.defaultImage}`;

        return (
          <Helmet titleTemplate={seo.titleTemplate}>
            {/* General tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />

            {/* OpenGraph tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content={seo.siteName} />
            <meta property="og:type" content="website" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={seo.twitterUsername} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
          </Helmet>
        );
      }}
    />
  );
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};

export default SEO;
