import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoint from 'create-breakpoints';

const layoutTypes = {
  small: `
    max-width: 480px;
  `,
  narrow: `
    max-width: 740px;
  `,
  default: `
    max-width: 1140px;
  `,
  wide: `
    max-width: 1250px;
  `
};

const LayoutContainer = styled.div`
  --h-spacing: 16px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--h-spacing);
  padding-right: var(--h-spacing);

  ${breakpoint.medium`
    --h-spacing: 20px;
  `};

  /* Set the layout type */
  ${props => layoutTypes[props.layout]};
`;

LayoutContainer.propTypes = {
  layout: PropTypes.PropTypes.oneOf(Object.keys(layoutTypes))
};

LayoutContainer.defaultProps = {
  layout: 'default'
};

export default LayoutContainer;
