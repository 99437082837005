import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Grid } from '@mindmatch/ui';
import breakpoint from 'create-breakpoints';
import LayoutContainer from 'components/LayoutContainer';
import Logo from 'components/Logo';
import Heading from 'components/Heading';
import Text from 'components/Text';

import { ReactComponent as EmailIcon } from 'images/icons/email.svg';

const Footer = styled.footer``;

Footer.Contact = styled.div`
  --v-spacing: 20px;

  background-color: var(--bg-gray-light);
  padding-top: var(--v-spacing);
  padding-bottom: var(--v-spacing);

  ${breakpoint.medium`
    --v-spacing: 40px;
  `};
`;

Footer.Nav = styled.div`
  --v-spacing: 20px;

  background-color: var(--bg-blue);
  color: var(--text-white);
  font-size: var(--font-size-medium);
  padding-top: var(--v-spacing);
  padding-bottom: var(--v-spacing);

  ${breakpoint.medium`
    --v-spacing: 80px;
  `};

  > ${LayoutContainer} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    ${breakpoint.small`
      flex-direction: row;
    `}
  }

  > ${LayoutContainer} > * {
    margin-bottom: 40px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 5px 0;

    ${breakpoint.small`
      display: inline-block;
      margin-right: 24px;
    `}
  }
`;

export default () => (
  <Footer>
    <Footer.Contact>
      <LayoutContainer layout="small">
        <Heading as="h2" mb="2" id="contact">
          Get in touch
        </Heading>
        <Text as="p" color="var(--text-gray)" textAlign="center" mb="5">
          Do you have questions? We would love to help!
        </Text>
        <Grid templateColumns="1fr" gap="25px">
          <div style={{ textAlign: 'center' }}>
            <EmailIcon
              width="60"
              height="60"
              viewBox="0 0 100 100"
              style={{ marginBottom: '15px' }}
            />
            <Heading style={{ marginBottom: '25px' }}>Email</Heading>
            <a href="mailto:hello@mindmatch.ai">hello@mindmatch.ai</a>
          </div>
        </Grid>
      </LayoutContainer>
    </Footer.Contact>
    <Footer.Nav>
      <LayoutContainer>
        <div>
          <Logo width="127" height="28" theme="light" />
        </div>
        <div>
          <ul>
            <li>
              <a href="https://jobsier.co" target="_blank">
                Job search
              </a>
            </li>
            <li>
              <Link to="/legal/terms-of-service">Terms of Service</Link>
            </li>
            <li>
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/legal/legal-notice">Legal Notice</Link>
            </li>
          </ul>
        </div>
      </LayoutContainer>
    </Footer.Nav>
  </Footer>
);
